.LockScreen label{
    font-size: 12px;
   font-weight: bold;
   align-items: flex-centre;
   padding: 10px;
   /* width: 130px !important; */
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   display: inline-block;
   /* max-width: 100%; */
   margin-bottom: 5px;
}



.LockScreen .w-full{
        width: auto !important;
        width: 30rem;
        margin-left: 1px;
        align-items: 0px;
    

}   
.LockScreen button {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 10px;
    margin-left: 10px;
    width: 80px;
    color:#555;
    background:rgb(190, 200, 202)
  
  } 
  .LockScreen .Dropdown{
    width: 30%;
    height: 34px;
     font-size: 10px;
     margin-left: 10px;
     line-height: 1.42857143;
     margin-top: 20px;
 
  }
.LockScreen .box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    /* width: 100%; */
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin-left: 10px;
    margin-right: 10px;
 }
 .LockScreen .date{
    display: inline-block;
     width: 20%;
     height: 34px;
     padding: 6px 12px;
     font-size: 14px;
     margin-left: 10px;
     line-height: 1.42857143;
     color: #555;
     background-color: #fff;
     background-image: none;
     border: 1px solid #ccc;
 }
 .LockScreen .print{
    background-color: #00a65a;
    border-color: #008d4c;
 }

 .LockScreen .Lock{
   color: red !important;
 }
 .LockScreen .Unlock{
   color: green !important;
 }