.profile .popup{
    position:fixed;
    top:30%;
    left:25%;
    width:25%;
    height:100%;
    border: 1px black;

    display: flex;
    justify-content: center;
    align-items: center;

}
.Profile .action {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}
.Profile .action.status-approved {
    background: #C8E6C9; 
    color: #256029;
}

.Profile .action.status-pending {
    background: #FFCDD2;
    color: #C63737;
}

.Profile .p-datatable .p-datatable-thead>tr>th {
    text-align: left;
    padding: 1rem;
    border: solid #1c1e1f;
    border-width: 0 0 1px;
    font-weight: 600;
    color: #080a0c;
    background: #bcb7bc;
    transition: box-shadow .2s;
}


