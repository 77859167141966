.AdminStockReturn{
   /* display: inline flex; */
   margin-left: 10px; 
     float: none; 
     margin-bottom: 10px;
     margin-right: 10px; 
     margin-top: 20px;
     border-radius: 10;
     border: 2px solid black;
     border-radius: 2px;
     align-content: center;
     align-items: center;
     padding-left: 10px;
     padding-top: 10px;
     padding-inline-start: 20px;
     padding-bottom: 10px;
     column-gap: 2px;
     width:auto;
     height: auto;
}

.AdminStockReturn .p-datatable .p-datatable-thead > tr > th {
   text-align: left;
   /* padding: 1rem 1rem; */
   border: 1px solid rgb(39 34 34);
   font-weight: 600;
   color: #495057;
   /* background: #f8f9fa; */
   transition: box-shadow 0.2s;
   padding: 6px;
   /* margin: 10px; */
 }

 .AdminStockReturn .p-datatable .p-datatable-tbody > tr > td {
   text-align: left;
   border: 1px solid rgb(39 34 34);
   /* padding: 1rem 1rem; */
   outline-color: #a6d5fa;
   /* background-color:#d2d6de; */
   padding: 4px;
   /* margin: 10px; */
 }
.AdminStockReturn .Pending{
   /* color: orange; */
   color: red !important;
}
.AdminStockReturn .Confirmed{
   /* color: green; */
   color: green !important;
}

.AdminStockReturn .Modified{
   /* color: orange; */
   font-weight: bold;
   color: rgb(35, 226, 35) !important;
   background-color: rgb(230, 184, 157) !important;
}
.AdminStockReturn .Unmodified{
   /* color: green; */
   background-color: rgb(167, 168, 166) !important;
}
.AdminStockReturn .Return_Rejected{
   /* color: red; */
   background-color:red;
}
.AdminStockReturn label{
   font-size: 12px;
   font-weight: bold;
   align-items: flex-centre;
   padding: 10px;
   /* width: 130px !important; */
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   display: inline-block;
   /* max-width: 100%; */
   margin-bottom: 5px;
}
.AdminStockReturn .box {
   position: relative;
      border-radius: 3px;
      background: #ffffff;
      border-top: 3px solid #d2d6de;
      margin-bottom: 20px;
      /* width: 100%; */
      box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
      margin-left: 10px;
      margin-right: 10px;
}
.AdminStockReturn .date{
   display: inline-block;
    width: 25%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    margin-left: 10px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
}
.AdminStockReturn .dropdown{
   width:75px;
   height: 30px;
   display: inline-block;
}

.AdminStockReturn .w-full{
       width: auto !important;
       width: 30rem;
       margin-left: 0px;
       align-items: 0px;
       margin-right: 50px;
}   

 .AdminStockReturn .table-header{
   /* display: flex; */
   justify-content: space-between;
 }
 
 .AdminStockReturn .p-datatable .p-datatable-header {
   background:white;
   color: #343a40;
   border: 1px solid #dee2e6;
   border-width: 1px 0 1px 0;
   padding: 5px;
   font-weight: 700;
}
.AdminStockReturn .box {
   position: relative;
   border-radius: 3px;
   background: #ffffff;
   border-top: 3px solid #d2d6de;
   margin-bottom: 20px;
   /* width: 100%; */
   box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
   margin-left: 10px;
   margin-right: 10px;
}
.AdminStockReturn {
   min-height: 100%;
   background-color: #ecf0f5;
   z-index: 800;
  
}
.AdminStockReturn .reset{
background-color: #f39c12;
border-color: #e08e0b;
color: #fff;

}
.AdminStockReturn button {
   display: inline-block;
   padding: 6px 12px;
   margin-bottom: 8px;
   font-size: 14px;
   font-weight: 400;
   line-height: 1.42857143;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   -ms-touch-action: manipulation;
   touch-action: manipulation;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background-image: none;
   border: 1px solid transparent;
   border-radius: 4px;
   margin-left: 10px;
   
 
 } 

 .AdminStockReturn .ReturnSubTable .p-datatable .p-datatable-thead > tr > th {
   text-align: left;
   /* padding: 1rem 1rem; */
   border: 1px solid rgb(239, 236, 236);
   font-weight: 600;
   color: #f0f4f8;
   background: #141f2b;
   transition: box-shadow 0.2s;
   padding: 6px;
 }
 .AdminStockReturn .ReturnSubTable .p-datatable .p-datatable-tbody > tr > td {
   text-align: left;
   border: 1px solid rgb(39 34 34);
   padding: 1rem 1rem;
   outline-color: #a6d5fa;
   background-color:#f9fcfc;
   color:rgb(21, 21, 21);
   padding: 4px;
 }

 
element.style {
}
.AdminStockReturn .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    /* padding: 1rem 1rem; */
    outline-color: #a6d5fa;
    /* background-color: #d2d6de; */
    padding: 4px;
    /* margin: 10px; */
}
.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}
/* .AdminStockReturn .ReturnSubTable .p-datatable-scrollable .p-datatable-thead > tr > th, .p-datatable-scrollable .p-datatable-tbody > tr > td, .p-datatable-scrollable .p-datatable-tfoot > tr > td {
   display: inline-block;
    flex: 1 1;
    align-items: center;
} */