.FranchiseComplaints {
    /* display: inline flex; */
    margin-left: 10px;
    float: none;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 10;
    border: 2px solid black;
    border-radius: 2px;
    align-content: center;
    align-items: center;
    padding-left: 10px;
    padding-top: 10px;
    padding-inline-start: 20px;
    padding-bottom: 10px;
    column-gap: 2px;
    width: auto;
    height: 650px;
}
.FranchiseComplaints label{
    font-size: 12px;
    font-weight: bold;
    align-items: flex-centre;
    padding: 10px;
    /* width: 130px !important; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: inline-block;
    /* max-width: 100%; */
    margin-bottom: 5px;
 }
 .FranchiseComplaints .box {
    position: relative;
       border-radius: 3px;
       background: #ffffff;
       border-top: 3px solid #d2d6de;
       margin-bottom: 20px;
       /* width: 100%; */
       box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
       margin-left: 10px;
       margin-right: 10px;
 }
 .FranchiseComplaints .date{
    display: inline-block;
     width: 25%;
     height: 34px;
     padding: 6px 12px;
     font-size: 14px;
     margin-left: 10px;
     line-height: 1.42857143;
     color: #555;
     background-color: #fff;
     background-image: none;
     border: 1px solid #ccc;
 }
 .FranchiseComplaints .w-full{
    width: auto !important;
    width: 30rem;
    margin-left: 0px;
    align-items: 0px;
    margin-right: 50px;
}   
.FranchiseComplaints .p-datatable .p-datatable-thead > tr > th {
text-align: left;
/* padding: 1rem 1rem; */
border: 1px solid rgb(39 34 34);
font-weight: 600;
color: #495057;
background: #f8f9fa;
transition: box-shadow 0.2s;
padding: 6px;
margin: 10px;
}

.FranchiseComplaints .p-datatable .p-datatable-tbody > tr > td {
text-align: left;
border: 1px solid rgb(39 34 34);
/* padding: 1rem 1rem; */
outline-color: #a6d5fa;
background-color:#d2d6de;
padding: 4px;
margin: 10px;
}
.FranchiseComplaints .table-header{
display: flex;
justify-content: space-between;
}

.FranchiseComplaints .p-datatable .p-datatable-header {
background:white;
color: #343a40;
border: 1px solid #dee2e6;
border-width: 1px 0 1px 0;
padding: 5px;
font-weight: 700;
border-collapse: collapse;
}
.FranchiseComplaints .box {
position: relative;
border-radius: 3px;
background: #ffffff;
border-top: 3px solid #d2d6de;
margin-bottom: 20px;
/* width: 100%; */
box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
margin-left: 10px;
margin-right: 10px;
}
.FranchiseComplaints {
min-height: 100%;
background-color: #ecf0f5;
z-index: 800;

}
.FranchiseComplaints .reset{
background-color: #f39c12;
border-color: #e08e0b;
color: #fff;

}
.FranchiseComplaints button {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-left: 10px;

} 
.FranchiseComplaints .content-header {
    position: relative;
    /* padding: 15px 15px 0 15px; */
}
.FranchiseComplaints .header{
    display: flex;
    justify-content:flex-end;
    margin-right: 10px;
    padding: 10px;
}
.FranchiseComplaints h3{
display: inline-flex;
flex-grow: 1;
}
.FranchiseComplaints .addButton{
    color: #fff;
    background-color: #d3b926;
    border-color: #ebd82d;
}

.FranchiseComplaints .updateButton{
    color: #fff;
    background-color: #d3b926;
    border-color: #ebd82d;
}

.FranchiseComplaints .addButton{
    color: #fff;
    background-color: #f39c12;
    border-color: #e08e0b;
}


 .FranchiseComplaints input{
    display: inline;
    margin-left: 2px;
    /* padding: 30px; */
    width: 500px;
}
.FranchiseComplaints .Dropdown{
    /* display: inline; */
    margin-left: 2px;
    /* padding: 30px; */
    width: 500px;
}
.FranchiseComplaints textarea{
    display: inline;
    margin-left: 2px;
    padding: 30px;
    width: 500px;
}
.FranchiseComplaints label{
    display: inline flex;
    margin-left: 10px;
    font-size: 12px;
    margin: 10px;
    align-items: flex-center;
    margin-bottom: 1px;
    margin-right: 10px;
    padding: 0px;
    width: 130px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}