.App {
  /* text-align: center; */
  padding-left: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 2vmin);
  color: white;
}
.App-home {
  width: 20px;

}

.wrapper .sidebar{
  background: rgb(5, 68, 104);
  position: fixed;
  top: 0;
  left: 0;
  width: 225px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.invalid-feedback{
  color: red;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px;
  border: 0;
  border-top: 1px solid #eee;
  
}

.button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-left: 10px;
  

} 
button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-left: 10px;
  

} 
.button{
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.filter{
  color: #fff;
  background-color: #3c8dbc;
    border-color: #367fa9;
}
.reset {
  background-color: #f39c12;
  border-color: #e08e0b;
  color: #fff;
  
}
.delete {
  background-color: #e40505;
  border-color: #c60b0b;
  color: #fff;
  
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  /* width: 100%; */
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  margin-left: 10px;
  margin-right: 10px;
}
.App .signUp{
  text-align: center;
}

.App .login{
  text-align: center;
}
.p-formgroup-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.Menu{
  display: flex;
  justify-content: space-around;
  margin-left: 50%;
}
.edit{
  color: #fff;
  background-color: #00c0ef;
  border-color: #00acd6;
}
.delete{
  color: #fff;
  background-color: #ef0000;
  border-color: #ef0000;
}
.product-badge.status-received {
  background: #48c34c; 
  color: #080707;
  padding: 2px;
  border-radius: 5px;
}

.product-badge.status-pending {
  background: #ef0000;
  color: #080707;
  padding: 2px;
  border-radius: 5px;

}

.product-badge.status-not_received {
  background: #ffde64;
  color: #080707;
  padding: 2px;
  border-radius: 5px;


}
.product-badge.status-return {
  background: #f35692;
  color: #080707;
  padding: 2px;
  border-radius: 5px;


}

.body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

.loading {
  text-align: center;
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}