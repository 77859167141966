.Franchise{
    /* display: inline flex; */
    /* margin-left: 10px;  */
      float: none; 
      /* margin-bottom: 10px; */
      /* margin-right: 10px;  */
      /* margin-top: 20px; */
      border-radius: 10;
      /* border: 2px solid black; */
      border-radius: 2px;
      align-content: center;
      align-items: center;
      padding-left: 20px;
      padding-top: 10px;
      padding-inline-start: 20px;
      /* padding-bottom: 10px; */
      column-gap: 2px;
      width:100%;
    

}
.Franchise input{
    display: inline;
    margin-left: 2px;
    padding: 30px;
    width: 450px;
}

.Franchise select{
    display: inline;
    margin-left: 2px;
    height: 35px;
    width: 450px;
}
.Franchise label{
    display: inline flex;
    margin-left: 10px;
    font-size: 12px;
    margin: 10px;
    align-items: flex-center;
    margin-bottom: 1px;
    margin-right: 10px;
    padding: 0px;
    width: 130px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}



/* .Franchise .w-full{
        width: auto !important;
        width: 30rem;
        margin-left: 1px;
        align-items: 0px;
    

}    */
/* .w-full {
    width: 30% ;
} */
 
.Franchise .button{
    display: flexbox;
    padding-bottom: 5px;
    margin-bottom: 10px;
    width: auto;
    margin-top: 10px;
}
.Franchise .button{
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-left: 10px;
    

} 
.Franchise .saveButton{
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
}
.Franchise {
    min-height: 100%;
    background-color: #ecf0f5;
    z-index: 800;
   
}
.Franchise .box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    /* width: 100%; */
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin-left: 10px;
    margin-right: 10px;
}

.Franchise .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    /* padding: 1rem 1rem; */
    border: 1px solid rgb(39 34 34);
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
    padding: 6px;
    margin: 10px;

  }

  .Franchise .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    /* padding: 1rem 1rem; */
    outline-color: #a6d5fa;
    background-color:#d2d6de;
    padding: 4px;
    /* margin: 10px; */
  }
  .Franchise .table-header{
    display: flex;
    justify-content: space-between;
  }
  
  .Franchise .p-datatable .p-datatable-header {
    background:white;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 5px;
    font-weight: 700;
}
.Franchise .surface-border {
    border-color: rgb(28, 27, 27)
}
.Franchise .addButton{
    color: #fff;
    background-color: #d3b926;
    border-color: #ebd82d;
}

.Franchise .addButton{
    color: #fff;
    background-color: #f39c12;
    border-color: #e08e0b;
}

.Franchise .accounts{
    color: #fff;
    background-color: #72a0fb;
    border-color: #72a0fb;
}
.Franchise .salesInvoice{
    color: #fff;
    background-color: #f39c12;
    border-color: #e08e0b;
}
.Franchise .salesReturn{
    color: #fff;
    background-color: #e15f0e;
    border-color: #e15f0e;
}
.Franchise .employee{
    color: #fff;
    background-color: #1148ed;
    border-color: #1148ed;
}
.Franchise .edit{
    color: #fff;
    background-color: #00c0ef;
    border-color: #00acd6;
}
.Franchise .delete{
    color: #fff;
    background-color: #ef0000;
    border-color: #ef0000;
}

.Franchise .header{
    display: flex;
    justify-content:flex-end;
    /* margin-left:10px; */
    margin-right: 10px;
    padding: 10px;
}
.Franchise .globalSearch{
    display: inline;
    margin-left: 2px;
    padding: 10px;
    width: 200px;
}
