.spinBar .dialog-demo .p-button {
    margin: 0 .5rem 0 0;
    background-color: #424242b2 !important;
    opacity: unset !important;
    /* background-color: hsla(0, 2%, 30%, 0.89); */
}
.spinBar .p-dialog {
    /* display: flex; */
    flex-direction: column;
    pointer-events: auto;
    max-height: 90%;
    transform: scale(1);
    position: relative;
    background: none;
    box-shadow: none;
}
.spinBar .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background:none;
    color: #495057;
    padding: 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    /* opacity: 0 ;  */
}
.spinBar .p-dialog-content {
     overflow-y: hidden; 
     overflow-x: hidden;
    flex-grow: 1;
}

.spinBar .p-dialog .p-dialog-content {
    /* background: #ffffff; */
    color: #495057;
    padding: 0 1.5rem 2rem 1.5rem;
    background: none;
    /* opacity: 0; */
}