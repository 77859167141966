.CustomerReport{
    /* display: inline flex; */
    margin-left: 10px; 
      float: none; 
      margin-bottom: 10px;
      margin-right: 10px; 
      margin-top: 20px;
      border-radius: 10;
      border: 2px solid black;
      border-radius: 2px;
      align-content: center;
      align-items: center;
      padding-left: 10px;
      padding-top: 10px;
      padding-inline-start: 20px;
      padding-bottom: 10px;
      column-gap: 2px;
      width:auto;
      height: auto;
 }