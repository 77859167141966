
.Stock{
    /* display: inline flex; */
      margin-left: 10px; 
        float: none; 
        margin-bottom: 10px;
        margin-right: 10px; 
        margin-top: 20px;
        border-radius: 10;
        border: 2px solid black;
        border-radius: 2px;
        align-content: center;
        align-items: center;
        padding-left: 10px;
        padding-top: 10px;
        padding-inline-start: 20px;
        padding-bottom: 10px;
        column-gap: 2px;
        width:auto;
        height: auto;
      
  
  }
  /* .Products .col {
       flex-grow: 4; 
      flex-basis: 0;
      padding: 0rem;
      padding-right: 4rem;
      align-items: flex-end !important;
  } */
  .Stock .w-full{
      width: auto !important;
      width: 30rem;
      margin-left: 1px;
      align-items: 0px;
  }
   
  .Stock label{
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
      margin: 10px;
      align-items: flex-center;
      margin-bottom: 1px;
      margin-right: 10px;
      padding: 0px;
      width: 130px !important;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  .Stock .p-dropdown {
      width: 200px;
      height: 35px;
      display:inline-flex;
      margin-left: 2px;


  }
  .Stock .p-inputtext {
    margin-top: -5px;
}
  
  .Stock .country-item-value img.flag {
      width: 17px;
      height: 35px;
  }
  .Stock .dropTest{
      
      margin-left: 2px;
      padding: 2px;
      width: auto!important;
      height: 35px;
  }
  .Stock input{
      display: inline;
      margin-left: 2px;
      padding: 2px;
      width: 200px;
  }
  /* .Stock input {
    display: inline;
    margin-left: 2px;
    padding: 2px;
    width: auto!important;
} */
  /* .Products button{
      display: flexbox;
  
      height: 30px;
      background-color: #008d4c;
      margin-left: 100px; 
   
   
  } */
  
  .Stock .box {
      position: relative;
      border-radius: 3px;
      background: #ffffff;
      border-top: 3px solid #d2d6de;
      margin-bottom: 20px;
      /* width: 100%; */
      box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
      margin-left: 10px;
      margin-right: 10px;
  }
   .Stock .button {
      display: inline-block;
      padding: 6px 12px;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;
      border-radius: 4px;
      margin-left: 10px;
      
  
  } 
  .Stock .button{
      color: #fff;
      background-color: #449d44;
      border-color: #398439;
  }
  .Stock {
      min-height: 100%;
      background-color: #ecf0f5;
      z-index: 800;
     
  }
   .Stock .box {
      position: relative;
      border-radius: 3px;
      background: #ffffff;
      border-top: 3px solid #d2d6de;
      margin-bottom: 20px;
      /* width: 100%; */
      box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
      margin-left: 10px;
      margin-right: 10px;
  }
  
  .Stock .p-datatable .p-datatable-thead > tr > th {
      text-align: left;
      /* padding: 1rem 1rem; */
      border: 1px solid rgb(39 34 34);
      font-weight: 600;
      color: #495057;
      background: #f8f9fa;
      transition: box-shadow 0.2s;
      padding: 6px;
      margin: 0px;
  
    }
  
    .Stock .p-datatable .p-datatable-tbody > tr > td {
      text-align: left;
      border: 1px solid rgb(39 34 34);
      /* padding: 1rem 1rem; */
      outline-color: #a6d5fa;
      background-color:#d2d6de;
      padding: 4px;
      margin: 0px;
    }
    .Stock .table-header{
      display: flex;
      justify-content: space-between;
    }
    
    .Stock .p-datatable .p-datatable-header {
      background:white;
      color: #343a40;
      border: 1px solid #dee2e6;
      border-width: 1px 0 1px 0;
      padding: 5px;
      font-weight: 700;
  }

  .Stock .backButton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
/* .Stock .p-dropdown {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    width: 450px;
    height: 30px;
    padding-bottom:-10px;

} */



