.AllAccounts{
    /* display: inline flex; */
    margin-left: 10px;
    float: none;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 20px;
    border-radius: 10;
    border: 2px solid black;
    border-radius: 2px;
    align-content: center;
    align-items: center;
    padding-left: 10px;
    padding-top: 10px;
    padding-inline-start: 20px;
    padding-bottom: 10px;
    column-gap: 2px;
    width: auto;
    height: auto;   
}
.AllAccounts button{
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-left: 10px;
    width: 120px;
}

.AllAccounts Select{
    display: inline-flex;
         width: 25%;
         height: 34px;
         padding: 6px 12px;
         font-size: 14px;
         margin-left: 10px;
         line-height: 1.42857143;
         color: #555;
         background-color: #fff;
         background-image: none;
         border: 1px solid #ccc;
}
.AllAccounts label{
    font-size: 12px;
    font-weight: bold;
    align-items: flex-centre;
    padding: 10px;
    width: 130px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: inline-block;
    /* max-width: 100%; */
    margin-bottom: 5px;
}
.AllAccounts .box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin-left: 10px;
    margin-right: 10px;
    }
    .AllAccounts .date{
        display: inline-block;
         width: 25%;
         height: 34px;
         padding: 6px 12px;
         font-size: 14px;
         margin-left: 10px;
         line-height: 1.42857143;
         color: #555;
         background-color: #fff;
         background-image: none;
         border: 1px solid #ccc;
     }