

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    /* padding: 0.75rem 1rem; */
    border-radius: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    -webkit-user-select: none;
    user-select: none;
   
    
}
.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #060607;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #d32f2f;
}
.Nav .UiName{
    /* border: 2px solid white; */
      background-color:white;
      color: black;
      /* border-width: 10px; */
      /* width:30% */
}
.Nav .societyName{
    width: 50%;
}
.Nav.FranchiseLogin{
    display: flex;
    justify-content:flex-start;
    align-items: center;
   
    /* background: linear-gradient(to top right, #336699 0%, #666699 100%); */
    background: linear-gradient(to top right, #20201fe1 0%, #353735 100%);

    min-height: 7vh;
    color: white;
    font-family: ui-monospace;
    width: 100%;
}
.Nav.AdminLogin{
    display: flex;
    justify-content:flex-start;
    align-items: center;
   
    background: linear-gradient(to top right, #336699 0%, #666699 100%);
    /* background: linear-gradient(to top right, #20201fe1 0%, #353735 100%); */

    min-height: 7vh;
    color: white;
    font-family: ui-monospace;
    width: 100%;
}

.card .p-menubar .p-submenu-list{
    padding: 0.25rem 0;
   
    /* font-family: 'Times New Roman', Times, serif; */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    /* rgb(101,166,145); */
    border: 0 none;
    /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
    width: 12.5rem;
}

.card .p-menubar .p-menuitem-link .p-menuitem-text {
    color:black;
}

.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #080808;
    margin-right: 0.5rem;
}

.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #080808;
    margin-left: 0.5rem;
}
/* .p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #f2f3f4;
} */
/* .p-tieredmenu {
    padding: 0.25rem 0;
    background: #3f3e3e;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    width: 12.5rem;
} */
/* .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #d3d5d8;
    margin-right: 0.5rem; 
} */
.SideBar .p-panelmenu .p-panelmenu-header > a {
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #a7c7e7;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}

.SideBar .p-sidebar {
    background: #ced4da;
    color: #495057;
    border: 0 none;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
}
.p-tieredmenu .p-submenu-list {
    /* position: absolute; */
    min-width: 100%;
    z-index: 1;
    display: none;
}
.p-panelmenu .p-panelmenu-header > a {
    padding: 5px;
    border: 1px solid #dee2e6;
    color: #f7f7f8;
    background: #151616;
    font-weight: 700;
    border-radius: 6px;
    transition: box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 5px;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    -webkit-user-select: none;
    user-select: none;
}
.p-panelmenu .p-panelmenu-header-link {
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    font-family: ui-monospace;
    position: relative;
    text-decoration: none;
}

.p-panelmenu .p-panelmenu-header > a {
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #e5eef8;
    background: #c6dff8;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #1e1e1f;
    border-color: #dee2e6;
    color: #f2f4f5;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}



.card .p-menubar .p-submenu-list{
    padding: 0.25rem 0;
   
    /* font-family: 'Times New Roman', Times, serif; */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    /* rgb(101,166,145); */
    border: 0 none;
    /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
    width: 12.5rem;
}

.card .p-menubar .p-menuitem-link .p-menuitem-text {
    color:black;
}

.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #080808;
    margin-right: 0.5rem;
}

.card .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #080808;
    margin-left: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #f2f3f4;
}
.p-tieredmenu {
    padding: 0.25rem 0;
    background: #3f3e3e;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #d3d5d8;
    margin-right: 0.5rem; 
}
.p-sidebar {
    background: #8fbeec;
    color: #495057;
    border: 0 none;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
}
.p-tieredmenu .p-submenu-list {
    /* position: absolute; */
    min-width: 100%;
    z-index: 1;
    display: none;
}
/* .p-panelmenu .p-panelmenu-header > a {
    padding: 5px;
    border: 1px solid #dee2e6;
    color: #f7f7f8;
    background: #151616;
    font-weight: 700;
    border-radius: 6px;
    transition: box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 5px;
    color: #495057;
    border-radius: 0;
    transition: box-shadow 0.2s;
    -webkit-user-select: none;
    user-select: none;
} */
.p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #1e1e1f;
    border-color: #dee2e6;
    color: #f2f4f5;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.SideBar .p-sidebar {

    background: #b8b2b2;
    color: #495057;
    border: 0 none;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}
/*  .p-panelmenu .p-panelmenu-header > a {
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #a3afa39e;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
} */

.Sidebar .p-panelmenu .p-panelmenu-header > a{
    padding: 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
	}