.AdminStockReturn .orders-subtable .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    /* padding: 1rem 1rem; */
    border: 1px solid rgb(239, 236, 236);
    font-weight: 600;
    color: #f0f4f8;
    background: #141f2b;
    transition: box-shadow 0.2s;
    padding: 6px;
    margin: 10px;
  }
 
  .AdminStockReturn .orders-subtable .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    /* padding: 1rem 1rem; */
    outline-color: #a6d5fa;
    background-color:#f9fcfc;
    color:rgb(21, 21, 21);
    padding: 4px;
    margin: 10px;
  }
  .AdminStockReturn .orders-subtable .table-header{
    display: flex;
    justify-content: space-between;
  }
  
  .AdminStockReturn .orders-subtable .p-datatable .p-datatable-header {
    background:white;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 5px;
    font-weight: 700;
 }

 .received-row {
  /* color: rgba(6, 238, 45, 0.932) !important; */
  color: #070!important;

  
  background-color: #f6f1f1 !important;
}

.pending-row {
  color: rgba(237, 36, 36, 0.932) !important;
  background-color: #f6f1f1 !important;

}