.FranchiseDashboard{
    background: aliceblue;
    height: 100%;

}

.FranchiseDashboard .BulletValues div {

    margin:2px;
    padding: 2px;
    width: 20%;
    height: 50%;
    border: 2px solid black;
    border-color: black;
    border-width: 2px;
}

.FranchiseDashboard .p-datatable .p-datatable-tbody > tr {
    /* background: #d98d8d; */
    color: #d71d25;
    transition: box-shadow 0.2s;
}
.FranchiseDashboard .p-datatable .p-datatable-tbody > th {
    /* background: #d98d8d; */
    color: #d71d25;
    transition: box-shadow 0.2s;
}

.FranchiseDashboard .p-datatable .p-datatable-header {
    background: #f8f9fa;
    color: #d71d25;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}
