.InvoicePrint Table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .InvoicePrint td, th {
    border: 1px solid #dddddd;
    text-align: left;
    font-size: 14px;
    border: 1px solid rgb(39 34 34);
    padding: 2px;
    font-size: 14px;
    color: #333;
  }
  p {
    margin: 0 0 10px;
}



 