.Accounts{
    /* display: inline flex; */
    margin-left: 10px; 
      float: none; 
      margin-bottom: 10px;
      margin-right: 10px; 
      margin-top: 20px;
      border-radius: 10;
      border: 2px solid black;
      border-radius: 2px;
      align-content: center;
      align-items: center;
      padding-left: 20px;
      padding-top: 10px;
      padding-inline-start: 20px;
      padding-bottom: 10px;
      column-gap: 2px;
      /* width:60%; */


    
}
.Accounts button{
    display: flexbox;
    padding-bottom: 5px;
    margin-bottom: 10px;
    width: 100px;
    margin-top: 10px;
    align-content: center;
    margin-left: 50px;

}
.Accounts input{
    display: inline flex;

    /* font-size: 12px;

    align-items: flex-centre;
    padding: 0px;
    width: 130px !important;
     */
}
.Accounts select{
    display: inline-block;
 
    font-size: 14px;
    padding: 6px 12px;
    align-items: flex-center;
    width: 212px ;
    height: 35px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}



.Accounts .form-control {
    display: flex;
    /* width: 25%; */ 
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.Accounts label {
    font-size: 12px;
    font-weight: bold;
    align-items: flex-centre;
    padding: 10px;
    width: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: inline-block;
    /* max-width: 100%; */
    margin-bottom: 5px;
}
.Accounts{
    /* display: inline flex; */
    margin-left: 10px; 
      float: none; 
      margin-bottom: 10px;
      margin-right: 10px; 
      margin-top: 20px;
      border-radius: 10;
      border: 2px solid black;
      border-radius: 2px;
      align-content: center;
      align-items: center;
      padding-left: 10px;
      padding-top: 10px;
      padding-inline-start: 20px;
      padding-bottom: 10px;
      column-gap: 2px;
      width:auto;
      height: auto;
 }
 .Accounts label{
    font-size: 12px;
    font-weight: bold;
    align-items: flex-centre;
    padding: 10px;
    /* width: 130px !important; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: inline-block;
    /* max-width: 100%; */
    margin-bottom: 5px;
 }
 .Accounts .box {
    position: relative;
       border-radius: 3px;
       background: #ffffff;
       border-top: 3px solid #d2d6de;
       margin-bottom: 20px;
       /* width: 100%; */
       box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
       margin-left: 10px;
       margin-right: 10px;
 }
 .Accounts .date{
    display: inline-block;
     width: 25%;
     height: 34px;
     padding: 6px 12px;
     font-size: 14px;
     margin-left: 10px;
     line-height: 1.42857143;
     color: #555;
     background-color: #fff;
     background-image: none;
     border: 1px solid #ccc;
 }
 .Accounts .dropdown{
    width:75px;
    height: 30px;
    display: inline-block;
 }
 
 .Accounts .w-full{
        width: auto !important;
        width: 30rem;
        margin-left: 0px;
        align-items: 0px;
        margin-right: 50px;
 }   
 .Accounts .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    /* padding: 1rem 1rem; */
    border: 1px solid rgb(39 34 34);
    font-weight: 600;
    /* color: #495057; */
    /* background: #f8f9fa; */
    transition: box-shadow 0.2s;
    padding: 6px;
    margin: 10px;
  }
 
  .Accounts .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    /* padding: 1rem 1rem; */
    outline-color: #a6d5fa;
    /* background-color:#d2d6de; */
    padding: 4px;
    margin: 10px;
  }
  .Accounts .table-header{
    display: flex;
    justify-content: space-between;
  }
  
  .Accounts .p-datatable .p-datatable-header {
    background:white;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 5px;
    font-weight: 700;
 }

 .Accounts .Pending{
  /* color: orange; */
  color: red !important;
}
.Accounts .Confirmed{
  /* color: green; */
  color: green !important;
}

.Accounts .Modified{
  /* color: orange; */
  font-weight: bold;
  color: rgb(35, 226, 35) !important;
  background-color: rgb(230, 184, 157) !important;
}
.AdminStockReturn .Unmodified{
  /* color: green; */
  background-color: rgb(167, 168, 166) !important;
}
 .Accounts .box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    /* width: 100%; */
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin-left: 10px;
    margin-right: 10px;
 }
 .Accounts {
    min-height: 100%;
    background-color:   #ebeab4;
    z-index: 800;
   
 }
 .Accounts .rowClass{

    background-color:  #a3d4a5 !important;
    color:  #a3d4a5 !important;
 }
 .Accounts .selectedRow {
    background-color: rgba(171, 179, 172, 0.932) !important;
    color:  #a3d4a5 !important;
    border-color: #e08e0b;
  }
  
 .Accounts .reset{
 background-color: #f39c12;
 border-color: #e08e0b;
 color: #fff;
 
 }
 .Accounts button {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-left: 10px;
    
  
  } 
  .Accounts .table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    border-top: 1px solid #131111
}
.Accounts .table-bordered { 
    border: 1px solid #131111
}

.Accounts td, th {
    border: 1px solid #dddddd;
    text-align: left;
    font-size: 14px;
    border: 1px solid rgb(39 34 34);
    padding: 2px;
    font-size: 14px;
    color: #333;
}
.Accounts .filterBox label {
    font-size: 12px;
    font-weight: bold;
    align-items: flex-centre;
    padding: 10px;
    width: 150px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: inline-block;
    /* max-width: 100%; */
    margin-bottom: 5px;
}

.Accounts .filterBox select {
    /* display: inline-block; */
    width: 25%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    margin-left: 10px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
}