.AddComplaint{
    /* display: inline flex; */
    margin-left: 10px; 
      float: none; 
      margin-bottom: 10px;
      margin-right: 10px; 
      margin-top: 20px;
      border-radius: 10;
      border: 2px solid black;
      border-radius: 2px;
      align-content: center;
      align-items: center;
      padding-left: 20px;
      padding-top: 10px;
      padding-inline-start: 20px;
      padding-bottom: 10px;
      column-gap: 2px;
      width:60%;


    
}
.AddComplaint button{
    display: flexbox;
    padding-bottom: 5px;
    margin-bottom: 10px;
    width: 100px;
    margin-top: 10px;
    align-content: center;
    margin-left: 50px;
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
    

}
.AddComplaint input{
    display: inline flex;

    /* font-size: 12px;

    align-items: flex-centre;
    padding: 0px;
    width: 130px !important;
    */
}
.AddComplaint Select{
    display: inline-flex;
 
    font-size: 12px;

    align-items: flex-center;
    width: 212px !important;
    height: 35px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.AddComplaint label{
    /* display: inline flex;

    font-size: 12px;

    align-items: flex-centre;
    padding: 0px;
    width: 130px !important;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
 */
    display: inline-block;
      margin-left: 10px;
      font-size: 12px;
      margin: 10px;
      align-items: flex-center;
      margin-bottom: 1px;
      margin-right: 10px;
      padding: 0px;
      width: 130px !important;
      font-family: Verdana, Geneva, Tahoma, sans-serif;

    
}
