.ProductReport{
    /* display: inline flex; */
    float: none; 
     border-radius: 10;
     border: 2px solid black;
     border-radius: 2px;
     align-content: center;
     align-items: center;
     padding-left: 20px;
     padding-top: 10px;
     padding-inline-start: 20px;
     padding-bottom: 20px;
    column-gap: 0px;
    margin-right: 10%;
    margin-left: 10px;
    margin-bottom: 30px;
}
.ProductReport label{
   display: inline flex;
   font-size: 12px;
   align-items: flex-centre;
   padding: 0px;
   width: 130px !important;
   font-family: Verdana, Geneva, Tahoma, sans-serif;
}



.ProductReport .w-full{
       width: auto !important;
       width: 30rem;
       margin-left: 0px;
       align-items: 0px;
       margin-right: 50px;
   

} 

