.popup{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0, 0,0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

}
.popup-inner{
    position: relative;
    /* padding:10px; */
    width:30%;
    height: auto;
    background-color:#FFF;
    border: 1px solid rgba(0, 0, 0.3);
    border-radius: 4px;
}
.popup-inner.close-btn{
    position:absolute;
    top: 16px;
    right:16px;
}
.popup .popup__footer {
    display: flex;
    /* Push the buttons to the right */
    justify-content: center;
    /* Border */
    border-top: 1px solid rgba(0, 0, 0.3);
    padding: 5px;
}

.popup .popup-content{
    text-align: left;
    padding: 10px;
}
.popup label{
    width:100px;
}
.popup .textBoxWidth{
    width:250px;
}