.adminDashboard{
   
   /* display: inline flex; */
   margin-left: 10px; 
     margin-bottom: 10px;
     margin-right: 10px; 
     margin-top: 20px;
     border-radius: 10;
     border: 2px solid black;
     border-radius: 2px;
     align-content: center;
     align-items: center;
     padding-left: 10px;
     padding-top: 10px;
     padding-inline-start: 20px;
     padding-bottom: 10px
}
.adminDashboard label{
    font-size: 12px;
    font-weight: bold;
    align-items: flex-centre;
    padding: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: inline-block;
    margin-bottom: 5px;
 }

 .adminDashboard .chartDiv{
    padding-left: 10px;
    margin: 10px;
    align-content: flex-start;
    border-spacing: 5px;
    border: 10px;
 }

 
