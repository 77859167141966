.Category {
    min-height: 100%;
    background-color: #ecf0f5;
    z-index: 800;
   
}
 .Category .box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    /* width: 100%; */
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin-left: 10px;
    margin-right: 10px;
}
 .Category .button {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-left: 10px;
    

} 
.Category .button{
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
}
.box .table{
    margin:10px;
    width:99%;
    margin-bottom: 10px;
}
.EditButton{
    color: #fff;
    background-color: #0d11eb;
    border-color: #0f0f10;
}
.Category .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    /* padding: 1rem 1rem; */
    border: 1px solid rgb(39 34 34);
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
    padding: 6px;
    margin: 10px;

  }

  .Category .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    /* padding: 1rem 1rem; */
    outline-color: #a6d5fa;
    background-color:#d2d6de;
    padding: 4px;
    margin: 10px;
  }
  .Category .table-header{
    display: flex;
    justify-content: space-between;
  }
  
  .Category .p-datatable .p-datatable-header {
    background:white;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 5px;
    font-weight: 700;
}
.Category .p-datatable {
    position: relative;
    padding: 10px;
}
