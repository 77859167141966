.btnApprove{
    height: 30px;
    background-color: green;
   
}
.btnDecline{
    height: 30px;
    background-color: red;
}
.PermissionTable .table-header{
    display: flex;
    justify-content: flex-end;
    
    
    
}
.PermissionTable .Admin{
    background-color: #9ed8db !important;

}
.PermissionTable .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    /* padding: 1rem 1rem; */
    border: 1px solid rgb(39 34 34);
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
    padding: 6px;
    /* margin: 10px; */

  }

  .PermissionTable .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid rgb(39 34 34);
    /* padding: 1rem 1rem; */
    outline-color: #a6d5fa;
    background-color:#f3f5f8;
    padding: 2px;
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif ;
    border-color: #335d7e !important;
    /* margin: 10px; */
  }
